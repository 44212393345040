import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  SvgIcon,
  Switch,
  TextField,
  Toolbar,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import LocationIcon from '@mui/icons-material/LocationOn'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import DevIcon from '@mui/icons-material/PriorityHigh'
import ChatIcon from '@mui/icons-material/Chat'
import MenuIcon from '@mui/icons-material/Menu'

import { getFromStorage, removeFromStorage, saveToStorage } from '../util/localStorage'
import UHaulTagLine from '@images/uhaul_tagline.svg'
import useWindowResize from '@hooks/useWindowResize'
import UHaulLogo from '@images/Uhaul_logo.svg'
import { getUhaulLink } from '@util/functions'
import ChatDialog from '@c/chat/chat-dialog'
import Dialog from '@c/dialog'

const DRAWER_WIDTH = 275
const UHAUL_NATIVE_KEY = 'UhaulNative'

export const SHOW_CHAT_IN_HEADER =
  CONTROLLER_NAME === 'SelfDispatch' &&
  ACTION_NAME !== 'LiveVerification_AwaitingApproval' &&
  ACTION_NAME !== 'AuthorizedDriver'

const account = [
  {
    icon: <AccountBoxIcon />,
    text: 'My Profile',
    href: getUhaulLink('/Account/Profile/'),
  },
  {
    icon: <DashboardIcon />,
    text: 'My Dashboard',
    href: getUhaulLink('/Orders/Rental/'),
  },
  {
    icon: <ShoppingCartIcon />,
    text: 'Cart',
    href: getUhaulLink('/Cart.aspx'),
  },
  {
    icon: <ExitToAppIcon />,
    text: 'Sign Out',
    href: getUhaulLink('/Orders/?signout'),
  },
]

const styles = {
  logo: {
    width: {
      xs: 150,
      md: 195,
    },
    height: 64,
    color: {
      xs: 'common.white',
      md: 'common.black',
    },
  },
  headerIcon: {
    fontSize: '2rem',
  },
  drawerPaper: {
    backgroundColor: {
      xs: 'custom.drawerBackgroundMobileBody',
      md: 'background.paper',
    },
    width: DRAWER_WIDTH,
  },
}

interface HeaderProps {
  forcedResponseError: boolean
  globalBannerText: string
  grammerCheck: boolean
  showGlobalBanner?: boolean
  themeMode: 'dark' | 'light'
  onThemeChange?: (themeMode: 'dark' | 'light') => void
  onForcedErrorChange?: (checked: boolean) => void
  onGlobalBannerChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onGrammerCheckChange?: (checked: boolean) => void
}
export default function Header({
  themeMode,
  forcedResponseError,
  showGlobalBanner,
  globalBannerText,
  grammerCheck,
  onThemeChange,
  onForcedErrorChange,
  onGlobalBannerChange,
  onGrammerCheckChange,
}: HeaderProps): React.JSX.Element {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const headerRef = useRef<HTMLElement>(null)
  const [showChat, setShowChat] = useState(false)
  const [demoOpen, setDemoOpen] = useState(false)
  const [nativeSession, setNativeSession] = useState(getFromStorage(UHAUL_NATIVE_KEY, false))
  const [minHeight, setMinHeight] = useState(64)
  const [rememberDemoKey, setRememberDemoKey] = useState(getFromStorage('rememberDemoKey', false))
  const [demoKey, setDemoKey] = useState(rememberDemoKey ? getFromStorage('demoKey', '') : '')
  const [mobileOpen, setMobileOpen] = useState(false)
  const resizeHandle = useCallback(() => {
    if (headerRef.current) {
      const bRect = headerRef.current.getBoundingClientRect()

      setMinHeight(bRect.height)
      setMobileOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current])
  const BannerText = useMemo(() => {
    return showGlobalBanner ? (
      <Box p={1} bgcolor='#579CDC'>
        <Typography variant='body1' align='center'>
          {globalBannerText}
        </Typography>
      </Box>
    ) : null
  }, [showGlobalBanner, globalBannerText])

  function handleDrawerToggle(): void {
    setMobileOpen(!mobileOpen)
  }
  function handleisDarkThemeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { checked } = event.target

    if (onThemeChange) onThemeChange(checked ? 'dark' : 'light')
  }
  function handleForceErrorChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { checked } = event.target

    if (onForcedErrorChange) onForcedErrorChange(checked)
  }
  function handleGrammerCheckChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { checked } = event.target

    if (onGrammerCheckChange) onGrammerCheckChange(checked)
  }
  function handleShowChat(): void {
    setShowChat(true)
  }
  function handleCloseChat(): void {
    setShowChat(false)
  }

  const drawer = (
    <Box
      sx={{
        backgroundColor: {
          xs: 'custom.drawerBackgroundMobileBody',
          md: 'background.paper',
        },
        color: {
          xs: 'common.white',
          md: 'text.primary',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SvgIcon sx={styles.logo} viewBox='0 0 100 16'>
          <UHaulLogo />
        </SvgIcon>
      </Box>
      <Divider />
      {ENV_NAME === 'Development' && (
        <List
          subheader={
            <ListSubheader
              sx={{
                backgroundColor: {
                  xs: '#121212',
                  md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.1) : alpha('#000000', 0.1),
                },
                color: {
                  xs: 'rgba(255, 255, 255, 0.7)',
                  md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.7) : alpha('#000000', 0.6),
                },
                textAlign: 'center',
              }}
              component='div'
            >
              Development Only
            </ListSubheader>
          }
        >
          <Divider />
          <ListItemButton component='a' href='/'>
            <ListItemText primary='Home' />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              window.location.href = '/Demo'
            }}
          >
            <ListItemText primary='Scenarios' />
          </ListItemButton>

          <ListSubheader
            sx={{
              backgroundColor: {
                xs: '#121212',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.1) : alpha('#000000', 0.1),
              },
              color: {
                xs: 'rgba(255, 255, 255, 0.7)',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.7) : alpha('#000000', 0.6),
              },
            }}
          >
            Sandbox
          </ListSubheader>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              window.location.href = '/Dev/Tool/Sandbox'
            }}
          >
            <ListItemText primary='Views' />
          </ListItemButton>
          <ListSubheader
            sx={{
              backgroundColor: {
                xs: '#121212',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.1) : alpha('#000000', 0.1),
              },
              color: {
                xs: 'rgba(255, 255, 255, 0.7)',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.7) : alpha('#000000', 0.6),
              },
            }}
          >
            POCs
          </ListSubheader>
          <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/POC/Scandit-Verifier'>
            <ListItemText primary='Scandit Verifier' />
          </ListItemButton>

          <ListSubheader
            sx={{
              backgroundColor: {
                xs: '#121212',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.1) : alpha('#000000', 0.1),
              },
              color: {
                xs: 'rgba(255, 255, 255, 0.7)',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.7) : alpha('#000000', 0.6),
              },
            }}
          >
            Tools
          </ListSubheader>
          <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/Tool/eat-viewer'>
            <ListItemText primary='EAT Viewer' />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/Tool/hybrid-checker'>
            <ListItemText primary='Hybrid Checker' />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component='a' href='/Dev/Tool/image-checker'>
            <ListItemText primary='Image Checker' />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component='a' href='/Contract/Start'>
            <ListItemText primary='Contract Tools' />
          </ListItemButton>

          <ListSubheader
            sx={{
              backgroundColor: {
                xs: '#121212',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.1) : alpha('#000000', 0.1),
              },
              color: {
                xs: 'rgba(255, 255, 255, 0.7)',
                md: theme.palette.mode === 'dark' ? alpha('#FFFFFF', 0.7) : alpha('#000000', 0.6),
              },
            }}
          >
            Toggles
          </ListSubheader>
          <ListItem>
            <ListItemText primary='Dark Theme' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={handleisDarkThemeChange}
                checked={themeMode === 'dark'}
                inputProps={{ 'aria-labelledby': 'switch-list-label-theme-mode' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='Global Banner' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                checked={showGlobalBanner}
                onChange={onGlobalBannerChange}
                inputProps={{ 'aria-labelledby': 'switch-list-label-force-global-banner' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='Force Response Error' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={handleForceErrorChange}
                checked={forcedResponseError}
                inputProps={{ 'aria-labelledby': 'switch-list-label-force-error' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='Grammar Check' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={handleGrammerCheckChange}
                checked={grammerCheck}
                inputProps={{ 'aria-labelledby': 'switch-list-label-grammer-check' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary='Native Session' />
            <ListItemSecondaryAction>
              <Switch
                edge='end'
                onChange={async () => {
                  if (UhaulNative) {
                    try {
                      await UhaulNative[nativeSession ? 'ClearNative' : 'SetNative']()

                      setNativeSession(getFromStorage(UHAUL_NATIVE_KEY, false))
                    } catch (error) {
                      console.error(error)
                    }
                  }
                }}
                checked={nativeSession}
                inputProps={{ 'aria-labelledby': 'switch-list-label-force-native-session' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </List>
      )}
      <List>
        {account.map((link, index) => (
          <ListItemButton key={index} component='a' href={link.href}>
            <ListItemIcon sx={{ color: 'inherit' }}>{link.icon}</ListItemIcon>
            <ListItemText primary={link.text} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  )

  useEffect(() => {
    resizeHandle()
  }, [resizeHandle, showGlobalBanner])

  useWindowResize(resizeHandle)

  return (
    <>
      <AppBar
        ref={headerRef}
        sx={{
          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? 'primary.main' : 'white'),
          borderBottom: (theme) =>
            theme.palette.mode === 'light' ? `4px ${theme.palette.primary.main} solid` : undefined,
          zIndex: (theme) => ({ md: theme.zIndex.drawer + 1 }),
        }}
      >
        {BannerText}
        <Toolbar>
          <IconButton
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              color: {
                xs: (theme) => (theme.palette.mode === 'dark' ? 'white' : '#3e3e3e'),
                md: (theme) => (theme.palette.mode === 'dark' ? 'white' : 'black'),
              },
              display: {
                md: 'none',
              },
            }}
            size='large'
          >
            <MenuIcon sx={styles.headerIcon} />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <SvgIcon
              sx={{
                width: {
                  xs: 150,
                  md: 195,
                },
                height: 64,
                color: 'black',
              }}
              viewBox='0 0 100 16'
            >
              <UHaulLogo />
            </SvgIcon>
            <SvgIcon
              sx={{ ml: 2, width: { xs: 0, md: 230 }, height: { xs: 0, md: 18 } }}
              viewBox='0 0 230 18'
            >
              <UHaulTagLine />
            </SvgIcon>
          </Box>
          <IconButton
            sx={{
              color: {
                xs: (theme) => (theme.palette.mode === 'dark' ? 'white' : '#3e3e3e'),
                md: (theme) => (theme.palette.mode === 'dark' ? 'white' : 'secondary.main'),
              },
            }}
            aria-label='location finder'
            href={getUhaulLink('/Locations')}
            size='large'
          >
            <LocationIcon sx={styles.headerIcon} />
          </IconButton>
          {SHOW_CHAT_IN_HEADER && (
            <IconButton
              sx={{
                color: {
                  xs: (theme) => (theme.palette.mode === 'dark' ? 'white' : '#3e3e3e'),
                  md: (theme) => (theme.palette.mode === 'dark' ? 'white' : 'secondary.main'),
                },
              }}
              onClick={handleShowChat}
              aria-label='chat with agent'
              size='large'
            >
              <ChatIcon sx={styles.headerIcon} />
            </IconButton>
          )}
          {ENV_NAME === 'Development' && (
            <IconButton sx={{ color: 'red' }} aria-label='development icon' size='large'>
              <DevIcon sx={styles.headerIcon} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{
          backgroundColor: {
            xs: 'custom.drawerBackgroundMobileBody',
            md: 'background.paper',
          },
          width: {
            md: DRAWER_WIDTH,
          },
          flexShrink: {
            md: 0,
          },
        }}
        aria-label='mailbox folders'
      >
        {isMobile ? (
          <Drawer
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              '& .MuiDrawer-paper': styles.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            sx={{
              '& .MuiDrawer-paper': {
                ...styles.drawerPaper,
                pt: `${minHeight <= 64 ? 0 : minHeight - 72}px`,
              },
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        )}
      </Box>
      <Box sx={{ minHeight }} />
      {SHOW_CHAT_IN_HEADER && (
        <ChatDialog open={showChat} onClose={handleCloseChat} maxWidth='sm' fullWidth />
      )}
      {ENV_NAME === 'Development' && (
        <Dialog
          open={demoOpen}
          title='Demo Key'
          onClose={() => {
            setDemoOpen(false)
          }}
          maxWidth='lg'
          fullWidth
          actions={
            <Box>
              <Button
                color='inherit'
                onClick={() => {
                  setDemoOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!demoKey.length}
                onClick={() => {
                  if (rememberDemoKey) {
                    saveToStorage('demoKey', demoKey)
                  }
                  window.location.href = `/Demo?token=${demoKey}`
                }}
              >
                Proceed
              </Button>
            </Box>
          }
        >
          <Typography paragraph>Please enter the demo key to proceed</Typography>
          <TextField
            label='Demo Key'
            fullWidth
            value={demoKey}
            onChange={(event) => {
              setDemoKey(event.target.value)
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberDemoKey}
                onChange={() => {
                  const nextState = !rememberDemoKey

                  if (!nextState) removeFromStorage('demoKey')
                  saveToStorage('rememberDemoKey', nextState)
                  setRememberDemoKey(nextState)
                }}
              />
            }
            label='Remember Key'
          />
        </Dialog>
      )}
    </>
  )
}
